$(document).ready(function() {
    // Calling LayerSlider on the target element
    $('#layerslider').layerSlider({
        skinsPath: '/libraries/layerslider/skins/',
        responsive: true,
        responsiveUnder: 768,
        layersContainer: 1280,
        // skin: 'noskin',
        hoverPrevNext: false
    });
});
